import { Injectable } from '@angular/core';
import { Variant } from '../models/variant';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Gene } from '../models/gene';
import { Classification } from '../models/classification';
import { VariantType } from '../models/variantType';
import { ClinicalSignificance } from '../models/clinicalSignificance';
import { Region } from '../models/region';
import { PopulationData } from '../models/populationData';
import { PredictiveData } from '../models/predictiveData';
import { FunctionalData } from '../models/functionalData';
import { SegregationData } from '../models/segregationData';
import { DeNovoData } from '../models/deNovoData';
import { AlleleData } from '../models/alleleData';
import { OtherDatabase } from '../models/otherDatabase';
import { OtherData } from '../models/otherData';
import { AuthService } from './auth.service';
import { Reference } from '../models/reference';
import { Unpublished } from '../models/unpublished';
import { Dictionary } from '../models/dictionary';
import { Version } from '../models/version';
import { PublishChanges } from '../models/publishChanges';
import { Filter } from '../models/filter';
import { AppConfigService } from './app-config.service';
import { PredictedPenetrance } from '../models/predictedPenetrance';
import { News } from '../models/news';
import { VariantChange } from '../models/variantChange';
import { BulkUpload } from '../models/bulkUpload';
import { UploadType } from '../models/uploadType';
import { FileUpload } from '../models/fileUpload';
import { ReferenceUpload } from '../models/referenceUpload';
import { BulkUploadVariants } from '../models/bulkUploadVariants';
import { BulkUploadReferences } from '../models/bulkUploadReferences';
import { BulkUploadReferenceVariants } from '../models/bulkUploadReferenceVariants';
import {ResearchSignificance} from '@app/models/researchSignificance';

@Injectable({
  providedIn: 'root'
})
export class VariantService {
  //private adpkdURL = AppConfigService.settings.apiBaseUrl;
  private _dictionary = new BehaviorSubject<Dictionary>(null);
  private _dictionaries = new BehaviorSubject<Dictionary[]>([]);
  private _currentDictionary: Dictionary;
  private _activeDictionary: Dictionary;
  private _workingDictionary: Dictionary;
  errorMessage: string;

  Dictionary(): Observable<Dictionary> {
    return this._dictionary.asObservable();
  }

  Dictionaries(): Observable<Dictionary[]> {
    return this._dictionaries.asObservable();
  }

  PublishedDictionary(): Dictionary {
    return this._dictionaries.value.find(d => d.IsActive);
  }

  CanAddEditVariants(): boolean {

    if (this._currentDictionary) {
      return !this._currentDictionary.IsActive;
    }

    return false;
  }

  IsWorkingDictionaryActive(): boolean {

    if (this._currentDictionary && this._workingDictionary) {
      if (this._currentDictionary == this._workingDictionary) {
        return true;
      }
    }

    return false;
  }

  constructor(private http: HttpClient, private authService: AuthService) {

    this.getActiveDictionary().subscribe({
      next: dictionary => {
        this._dictionary.next(dictionary);
      },
      error: err => this.errorMessage = err
    });

    this.Dictionary().subscribe(data => {
      this._currentDictionary = data;
    });

    this.authService.isAuthenticated().subscribe(data => {
      if (data) {
        this.loadDictionaries();
      }
    });
  }

  getApiUrl(): string {
    var url = "https://localhost:44382/api/";

    if (AppConfigService.settings.apiBaseUrl.indexOf("localhost") != -1) {
      url = 'https://localhost:44382/api/';
    } else if (AppConfigService.settings.apiBaseUrl.indexOf("adpkd-dev-api") != -1) {
      url = 'https://adpkd-dev-api.mayo.edu/api/';
    } else if (AppConfigService.settings.apiBaseUrl.indexOf("adpkd-int-api") != -1) {
      url = 'https://adpkd-int-api-v3.extsharedevnc-asev3.appserviceenvironment.net/api/'; //'https://adpkd-int-api.mayo.edu/api/';
    } else if (AppConfigService.settings.apiBaseUrl.indexOf("adpkd-api") != -1) {
      url = 'https://adpkd-api.mayo.edu/api/';
    }
    return url;
  }


  loadDictionaries() {
    this.getDictionaries().subscribe({
      next: dictionaries => {
        this._dictionaries.next(dictionaries);

        this._activeDictionary = dictionaries.find(dictionary => dictionary.IsActive);
        this._workingDictionary = dictionaries.find(dictionary => dictionary.PublishDate == null);
      },
      error: err => this.errorMessage = err
    });
  }

  changeToActiveDictionary() {

    this.changeDictionary(this._activeDictionary);
  }

  changeDictionary(dictionary: Dictionary) {
    this._dictionary.next(dictionary);
  }

  getVariants(): Observable<Variant[]> {

    if (this.authService.authenticated && this._currentDictionary) {
      return this.http.get<Variant[]>(this.getApiUrl() + 'Variants/' + this._currentDictionary.DictionaryID)
        .pipe(catchError(this.handleError));
    }
    else {
      return this.http.get<Variant[]>(this.getApiUrl() + 'Variants')
        .pipe(catchError(this.handleError));
    }
  }

  getVariantsByWorkingDictionary(): Observable<Variant[]> {

    if (this.authService.authenticated && this._workingDictionary ) {
      return this.http.get<Variant[]>(this.getApiUrl() + 'Variants/' + this._workingDictionary.DictionaryID + '/true')
        .pipe(catchError(this.handleError));
    }

    return null;
  }

  getPedigreeCount(filter: Filter): Observable<number> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<number>(this.getApiUrl() + 'Pedigree/' + this._currentDictionary.DictionaryID, filter, options)
      .pipe(catchError(this.handleError));
  }

  getVariantByID(id: number, dictionaryId: number): Observable<Variant> {

    if (dictionaryId == null) {
      return this.http.get<Variant>(this.getApiUrl() + 'Variants/' + this._currentDictionary.DictionaryID + '/' + id)
        .pipe(catchError(this.handleError));
    }
    else {
      return this.http.get<Variant>(this.getApiUrl() + 'Variants/' + dictionaryId + '/' + id)
        .pipe(catchError(this.handleError));
    }
  }

  getVariantChangesByID(id: number): Observable<VariantChange[]> {
    return this.http.get<VariantChange[]>(this.getApiUrl() + 'VariantChange/' + id)
      .pipe(catchError(this.handleError));
  }

  addVariant(variant: Variant) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<Variant>(this.getApiUrl() + 'Variants/' + this._currentDictionary.DictionaryID, variant, options)
      .pipe(catchError(this.handleError));
  }

  deleteVariant(variant: Variant) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.delete<Variant>(this.getApiUrl() + 'Variants/' + this._currentDictionary.DictionaryID + '/' + variant.VariantID, options)
      .pipe(catchError(this.handleError));
  }

  updateVariant(variant: Variant) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<Variant>(this.getApiUrl() + 'Variants/' + this._currentDictionary.DictionaryID + '/' + variant.VariantID, variant, options)
      .pipe(catchError(this.handleError));
  }

  getGenes(): Observable<Gene[]> {
    return this.http.get<Gene[]>(this.getApiUrl() + 'Genes')
      .pipe(catchError(this.handleError));
  }

  getClassifications(): Observable<Classification[]> {
    return this.http.get<Classification[]>(this.getApiUrl() + 'Classifications')
      .pipe(catchError(this.handleError));
  }

  getVariantTypes(): Observable<VariantType[]> {
    return this.http.get<VariantType[]>(this.getApiUrl() + 'VariantTypes')
      .pipe(catchError(this.handleError));
  }

  getClinicalSignificances(): Observable<ClinicalSignificance[]> {
    return this.http.get<ClinicalSignificance[]>(this.getApiUrl() + 'ClinicalSignificances')
      .pipe(catchError(this.handleError));
  }

  getResearchSignificances(): Observable<ResearchSignificance[]> {
    return this.http.get<ResearchSignificance[]>(this.getApiUrl() + 'ResearchSignificances')
      .pipe(catchError(this.handleError));
  }

  getPredictedPenetrances(): Observable<PredictedPenetrance[]> {
    return this.http.get<PredictedPenetrance[]>(this.getApiUrl() + 'PredictedPenetrances')
      .pipe(catchError(this.handleError));
  }

  getRegions(): Observable<Region[]> {
    return this.http.get<Region[]>(this.getApiUrl() + 'Regions')
      .pipe(catchError(this.handleError));
  }

  getPopulationData(): Observable<PopulationData[]> {
    return this.http.get<PopulationData[]>(this.getApiUrl() + 'PopulationData')
      .pipe(catchError(this.handleError));
  }

  getPredictiveData(): Observable<PredictiveData[]> {
    return this.http.get<PredictiveData[]>(this.getApiUrl() + 'PredictiveData')
      .pipe(catchError(this.handleError));
  }

  getFunctionalData(): Observable<FunctionalData[]> {
    return this.http.get<FunctionalData[]>(this.getApiUrl() + 'FunctionalData')
      .pipe(catchError(this.handleError));
  }

  getSegregationData(): Observable<SegregationData[]> {
    return this.http.get<SegregationData[]>(this.getApiUrl() + 'SegregationData')
      .pipe(catchError(this.handleError));
  }

  getDeNovoData(): Observable<DeNovoData[]> {
    return this.http.get<DeNovoData[]>(this.getApiUrl() + 'DeNovoData')
      .pipe(catchError(this.handleError));
  }

  getAlleleData(): Observable<AlleleData[]> {
    return this.http.get<AlleleData[]>(this.getApiUrl() + 'AlleleData')
      .pipe(catchError(this.handleError));
  }

  getOtherDatabase(): Observable<OtherDatabase[]> {
    return this.http.get<OtherDatabase[]>(this.getApiUrl() + 'OtherDatabase')
      .pipe(catchError(this.handleError));
  }

  getOtherData(): Observable<OtherData[]> {
    return this.http.get<OtherData[]>(this.getApiUrl() + 'OtherData')
      .pipe(catchError(this.handleError));
  }

  getUploadTypes(): Observable<UploadType[]> {
    return this.http.get<UploadType[]>(this.getApiUrl() + 'UploadTypes')
      .pipe(catchError(this.handleError));
  }

  getReferences(): Observable<Reference[]> {
    return this.http.get<Reference[]>(this.getApiUrl() + 'References')
      .pipe(catchError(this.handleError));
  }

  getBulkUploads(): Observable<BulkUpload[]> {
    return this.http.get<BulkUpload[]>(this.getApiUrl() + 'BulkUpload')
      .pipe(catchError(this.handleError));
  }

  getReferenceByID(id: number): Observable<Reference> {
    return this.http.get<Reference>(this.getApiUrl() + 'References/' + id)
      .pipe(catchError(this.handleError));
  }

  addReference(reference: Reference) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<Reference>(this.getApiUrl() + 'References/', reference, options)
      .pipe(catchError(this.handleError));
  }

  deleteReference(reference: Reference) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.delete<Reference>(this.getApiUrl() + 'References/' + reference.ReferenceID,  options)
      .pipe(catchError(this.handleError));
  }

  updateReference(reference: Reference) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<Reference>(this.getApiUrl() + 'References/' + reference.ReferenceID, reference, options)
      .pipe(catchError(this.handleError));
  }

  getUnpublishedReferenceByID(id: number): Observable<Unpublished[]> {
    return this.http.get<Unpublished[]>(this.getApiUrl() + 'UnpublishedReferences/' + id)
      .pipe(catchError(this.handleError));
  }

  private getDictionaries(): Observable<Dictionary[]> {
    return this.http.get<Dictionary[]>(this.getApiUrl() + 'Dictionary')
      .pipe(catchError(this.handleError));
  }

  getDictionaryByID(id: number): Observable<Dictionary> {
    return this.http.get<Dictionary>(this.getApiUrl() + 'Dictionary/' + id)
      .pipe(catchError(this.handleError));
  }

  getActiveDictionary(): Observable<Dictionary> {
    return this.http.get<Dictionary>(this.getApiUrl() + 'Dictionary/Active/True')
      .pipe(catchError(this.handleError));
  }

  addDictionary(dictionary: Dictionary, copiedId?: number) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    if (copiedId) {
      return this.http.post<Dictionary>(this.getApiUrl() + 'Dictionary/'+copiedId, dictionary, options)
        .pipe(catchError(this.handleError));
    }
    else {
      return this.http.post<Dictionary>(this.getApiUrl() + 'Dictionary/', dictionary, options)
        .pipe(catchError(this.handleError));
    }
  }

  deleteDictionary(dictionary: Dictionary) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.delete<Dictionary>(this.getApiUrl() + 'Dictionary/' + dictionary.DictionaryID, options)
      .pipe(catchError(this.handleError));
  }

  updateDictionary(dictionary: Dictionary) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<Dictionary>(this.getApiUrl() + 'Dictionary/' + dictionary.DictionaryID, dictionary, options)
      .pipe(catchError(this.handleError));
  }

  getNextMajorDictionaryVersion(): Observable<Version> {
    return this.http.get<Version>(this.getApiUrl() + 'Version')
      .pipe(catchError(this.handleError));
  }

  getNextMinorDictionaryVersionByID(id: number): Observable<Version> {
    return this.http.get<Version>(this.getApiUrl() + 'Version/' + id)
      .pipe(catchError(this.handleError));
  }

  getDictionaryChangesByID(fromId: number, toId: number): Observable<PublishChanges> {
    return this.http.get<PublishChanges>(this.getApiUrl() + 'Publish/Changes/' + fromId + '/' + toId)
      .pipe(catchError(this.handleError));
  }

  getDictionaryChangesFromPublishedByID(id: number): Observable<PublishChanges> {
    return this.http.get<PublishChanges>(this.getApiUrl() + 'Publish/Changes/' + id)
      .pipe(catchError(this.handleError));
  }

  publishDictionary(dictionary: Dictionary) {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<Dictionary>(this.getApiUrl() + 'Publish/' + dictionary.DictionaryID, dictionary, options)
      .pipe(catchError(this.handleError));
  }

  rollbackDictionary() {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.delete<Dictionary>(this.getApiUrl() + 'Publish/' + this.PublishedDictionary().DictionaryID, options)
      .pipe(catchError(this.handleError));
  }

  getNews(): Observable<News[]> {
    return this.http.get<News[]>(this.getApiUrl() + 'News')
      .pipe(catchError(this.handleError));
  }

  uploadExcelFile(uploadTypeID: number, data: FileUpload): any {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    switch (uploadTypeID) {
      case 1:
        return this.http.post<BulkUploadVariants>(this.getApiUrl() + 'FileUpload/1', data, options)
          .pipe(catchError(this.handleError));
      case 2:
        return this.http.post<BulkUploadReferences>(this.getApiUrl() + 'FileUpload/2', data, options)
          .pipe(catchError(this.handleError));
      case 3:
        return this.http.post<BulkUploadReferenceVariants>(this.getApiUrl() + 'FileUpload/3', data, options)
          .pipe(catchError(this.handleError));
    }

    return null;
  }

  verifyBulkUploadVariants(bulkUpload: BulkUploadVariants): Observable<BulkUploadVariants> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<BulkUploadVariants>(this.getApiUrl() + 'BulkUploadVariants/', bulkUpload, options)
      .pipe(catchError(this.handleError));
  }

  uploadBulkUploadVariants(bulkUpload: BulkUploadVariants): Observable<BulkUploadVariants> {

    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<BulkUploadVariants>(this.getApiUrl() + 'BulkUploadVariants/', bulkUpload, options)
      .pipe(catchError(this.handleError));
  }

  verifyBulkUploadReferences(bulkUpload: BulkUploadReferences): Observable<BulkUploadReferences> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<BulkUploadReferences>(this.getApiUrl() + 'BulkUploadReferences/', bulkUpload, options)
      .pipe(catchError(this.handleError));
  }

  uploadBulkUploadReferences(bulkUpload: BulkUploadReferences): Observable<BulkUploadReferences> {

    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<BulkUploadReferences>(this.getApiUrl() + 'BulkUploadReferences/', bulkUpload, options)
      .pipe(catchError(this.handleError));
  }

  verifyBulkUploadReferenceVariants(bulkUpload: BulkUploadReferenceVariants): Observable<BulkUploadReferenceVariants> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<BulkUploadReferenceVariants>(this.getApiUrl() + 'BulkUploadReferenceVariants/', bulkUpload, options)
      .pipe(catchError(this.handleError));
  }

  uploadBulkUploadReferenceVariants(bulkUpload: BulkUploadReferenceVariants): Observable<BulkUploadReferenceVariants> {

    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<BulkUploadReferenceVariants>(this.getApiUrl() + 'BulkUploadReferenceVariants/', bulkUpload, options)
      .pipe(catchError(this.handleError));
  }

  convertObjectToCSV(objects: any) {
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(objects[0])
    let csv = objects.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift(header.join(','))

    return new Blob([csv.join('\r\n')], { type: 'text/csv' });
  }

  downloadBlobFile(blob: Blob, fileName: string) {

    var url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      var a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
